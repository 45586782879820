// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";

// Frontend controllers
import BookmarkController from "./bookmark_controller";
import CartStockUpdateController from "./cart_stock_update_controller";
import CheckValidEmailController from "./check_valid_email_controller";
import CheckoutController from "./checkout_controller";
import CollectionController from "./collection_controller";
import CountdownTimerController from "./countdown_timer_controller";
import CouponsController from "./coupons_controller";
import CustomRadioController from "./custom_radio_controller";
import DismissController from "./dismiss_controller";
import GeolocationController from "./geolocation_controller";
import GoogleMapController from "./google_map_controller";
import InfiniteScrollController from "./infinite_scroll_controller";
import OrderTrackingController from "./order_tracking_controller";
import QrPaymentController from "./qr_payment_controller";
import TabsController from "./tabs_controller";
import ToggleBooleanController from "./toggle_boolean_controller";
import VariantSelectController from "./variant_select_controller";

// Shared controllers
import ContentRevealController from "../content_reveal_controller";
import CopyToClipboardController from "../copy_to_clipboard_controller";
import DateFieldController from "../date_field_controller";
import DrawerController from "../drawer_controller";
import HorizontalScrollController from "../horizontal_scroll_controller";
import OnlineUsersController from "../online_users_controller";
import PlacesController from "../places_controller";
import SearchController from "../search_controller";
import SearchResultsController from "../search_results_controller";

// const application = Application.start();
// const context = require.context("controllers/frontend", true, /\.js$/);
// application.load(definitionsFromContext(context));

const application = Application.start();

// Frontend Controller Registration
application.register("frontend--bookmark", BookmarkController);
application.register("frontend--cart-stock-update", CartStockUpdateController);
application.register("frontend--check-valid-email", CheckValidEmailController);
application.register("frontend--checkout", CheckoutController);
application.register("frontend--collection", CollectionController);
application.register("frontend--countdown-timer", CountdownTimerController);
application.register("frontend--coupons", CouponsController);
application.register("frontend--custom-radio", CustomRadioController);
application.register("frontend--dismiss", DismissController);
application.register("frontend--geolocation", GeolocationController);
application.register("frontend--google-map", GoogleMapController);
application.register("frontend--infinite-scroll", InfiniteScrollController);
application.register("frontend--order-tracking", OrderTrackingController);
application.register("frontend--qr-payment", QrPaymentController);
application.register("frontend--tabs", TabsController);
application.register("frontend--toggle-boolean", ToggleBooleanController);
application.register("frontend--variant-select", VariantSelectController);

// Shared Controller Registration
application.register("content-reveal", ContentRevealController);
application.register("copy-to-clipboard", CopyToClipboardController);
application.register("date-field", DateFieldController);
application.register("drawer", DrawerController);
application.register("horizontal-scroll", HorizontalScrollController);
application.register("online-users", OnlineUsersController);
application.register("places", PlacesController);
application.register("search", SearchController);
application.register("search-results", SearchResultsController);
