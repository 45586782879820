import { Controller } from "stimulus";
import consumer from "channels/consumer";

export default class extends Controller {
  static targets = ["onlineUsers"];

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "OnlineChannel" },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    let el = document.querySelector(`[data-online-user-id='${data.user_id}']`);

    if (data.status == "online" && el) {
      this.onlineUsersTarget.insertAdjacentHTML("beforeend", data.html);
      const insertedNode = document.querySelector(
        `[data-id='${data.user_id}']`
      );
      $(insertedNode).tooltip();
    } else if (data.status == "offline" && el != null) {
      el.remove();
    }
  }
}
