import { Controller } from "stimulus";
import OriginMarkerIcon from "../../images/fulfilling_branch_icon.png";

export default class extends Controller {
  static values = { orderInfo: Object };
  static targets = ["map", "orderInfo"];

  connect() {
    this.origin = this.orderInfoValue.origin;
    this.destination = this.orderInfoValue.destination;

    Turbolinks.clearCache();
    if (typeof google != "undefined") {
      this.initMap();
    }
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(-17.7833411, -63.1821504),
      zoom: 14,
      gestureHandling: "greedy",
      disableDefaultUI: true,
    });

    [this.origin, this.destination].forEach((coords) => {
      console.log(coords);
      new google.maps.Marker({
        position: { lat: parseFloat(coords.lat), lng: parseFloat(coords.lng) },
        map: this.map,
        icon: {
          url: OriginMarkerIcon,
          size: new google.maps.Size(56, 61),
        },
      });
    });
  }
}
