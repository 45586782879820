import { Controller } from "stimulus";

import "flatpickr";

export default class extends Controller {
  static targets = ["field"];
  static values = {
    type: String,
    isBranchOpen: Boolean,
    minSchedulableTime: String,
  };

  connect() {
    this._initializeFlatpickr();
  }

  _initializeFlatpickr() {
    this.fieldTargets.map((fieldTarget) => {
      if (this.data.get("delivery-schedulable")) {
        const date = new Date();

        const maximumSchedulableTime = date.setHours(
          date.getHours() + parseInt(this.data.get("max-schedulable-time"))
        );

        this.flatpickr = flatpickr(fieldTarget, {
          minDate: new Date(this.minSchedulableTimeValue),
          maxDate: maximumSchedulableTime,
          minTime: this._minTime(),
          maxTime: "19:30",
          enableTime: true,
          dateFormat: "Y-m-d H:i",
          disableMobile: true,
          onChange: this._handleChange.bind(this),
        });
      } else {
        this.flatpickr = flatpickr(fieldTarget, {
          enableTime: true,
          dateFormat: "Y-m-d H:i",
        });
      }
    });
  }

  _handleChange(dates, str, instance) {
    console.log(instance);
  }

  _minTime() {
    const date = new Date(this.minSchedulableTimeValue);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${hours}:${minutes}`;
  }

  _maxTime() {
    const date = new Date(this.maxSchedulableTimeValue);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${hours}:${minutes}`;
  }
}
