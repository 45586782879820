import { Controller } from "stimulus";
import { Notyf } from "notyf";

export default class extends Controller {
  static values = { url: String, cartId: Number };
  static targets = ["button", "loadingIndicator"];

  markAsRead(evt) {
    evt.preventDefault();

    this._showLoadingIndicator();

    Rails.ajax({
      url: this.urlValue,
      type: "patch",
      data: `cart_id=${this.cartIdValue}`,
      success: (data) => {
        this._removeWarning();
        this._showSuccessToast(data.message);
      },
      error: function (err) {
        console.log(err);
      },
    });
  }

  _showLoadingIndicator() {
    this.loadingIndicatorTarget.classList.remove("hidden");
  }

  _removeWarning() {
    this.element.remove();
  }

  _showSuccessToast(message) {
    const notyf = new Notyf({
      duration: 3000,
      position: {
        x: "right",
        y: "top",
      },
    });
    notyf.success(message);
  }
}
