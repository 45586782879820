import { Controller } from "stimulus";

const upKey = 38;
const downKey = 40;
const enterKey = 13;
const escapeKey = 27;
const navigationKeys = [upKey, downKey, enterKey, escapeKey];

export default class extends Controller {
  static targets = ["result"];

  connect() {
    this.currentResultIndex = 0;
    this.selectCurrentResult();
  }

  navigateResults(event) {
    if (!navigationKeys.includes(event.keyCode)) {
      return;
    }

    event.preventDefault();
    switch (event.keyCode) {
      case downKey:
        this.selectNextResult();
        break;
      case upKey:
        this.selectPreviousResult();
        break;
      case enterKey:
        this.goToSelectedResult();
        break;
    }
  }

  // private
  selectCurrentResult() {
    this.resultTargets.forEach((element, index) => {
      element.classList.toggle(
        "search__result--current",
        index == this.currentResultIndex
      );
    });
  }

  selectNextResult() {
    if (this.currentResultIndex < this.resultTargets.length - 1) {
      this.currentResultIndex++;
      this.selectCurrentResult();
    }
  }

  selectPreviousResult() {
    if (this.currentResultIndex > 0) {
      this.currentResultIndex--;
      this.selectCurrentResult();
    }
  }

  goToSelectedResult() {
    if (this.resultTargets[this.currentResultIndex].dataset.url) {
      window.location.href =
        this.resultTargets[this.currentResultIndex].dataset.url;
    } else {
      this.resultTargets[this.currentResultIndex].firstElementChild.click();
    }
  }

  goToResultSalesPage() {
    if (this.resultTargets[this.currentResultIndex].dataset.salesUrl) {
      window.location.href =
        this.resultTargets[this.currentResultIndex].dataset.salesUrl;
    } else {
      this.resultTargets[this.currentResultIndex].firstElementChild.click();
    }
  }

  removeResults() {
    this.resultTargets.display = "none";
  }
}
