document.addEventListener("turbolinks:load", function () {
  let slickable = document.querySelectorAll("[data-behavior=slickable]");

  if (slickable.length > 0) {
    slickable.forEach((slick) => {
      let numberOfSlides = slick.dataset.slickableSlides;
      let numberOfSlidesMedimum = slick.dataset.slickableSlidesMedium;
      let numberOfSlidesSmall = slick.dataset.slickableSlidesSmall;
      let hasDots = slick.dataset.slickableDots;

      $(slick).slick({
        infinite: true,
        centerMode: false,
        adaptiveHeight: false,
        centerPadding: "20px",
        mobileFirst: true,
        slidesToShow: parseInt(numberOfSlides),
        slidesToScroll: parseInt(numberOfSlides),
        prevArrow: false,
        nextArrow: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              dots: JSON.parse(hasDots),
              centerMode: false,
              centerPadding: "40px",
              slidesToShow: parseInt(numberOfSlides),
              slidesToScroll: parseInt(numberOfSlides),
            },
          },
          {
            breakpoint: 600,
            settings: {
              arrows: false,
              centerMode: false,
              centerPadding: "20px",
              slidesToShow: parseInt(numberOfSlidesMedimum),
              slidesToScroll: parseInt(numberOfSlidesMedimum),
            },
          },
          {
            breakpoint: 320,
            settings: {
              arrows: true,
              dots: false,
              centerMode: false,
              centerPadding: "20px",
              slidesToShow: parseInt(numberOfSlidesSmall),
              slidesToScroll: parseInt(numberOfSlidesSmall),
            },
          },
        ],
      });
    });
  }
});
