import {Controller} from "stimulus";
import { Notyf } from "notyf";

export default class extends Controller{
  static targets = ["bookmarkButton", "bookmarked"]

  disconnect(){
    Turbolinks.clearCache();
  }

  toggleBookmark(e){
    e.preventDefault();

    let data = new FormData();
    data.append(`${this.bookmarkButtonTarget.dataset.bookmarkable_type}_id`, e.target.dataset.bookmarkable);

    Rails.ajax({
      url: this.bookmarkButtonTarget.dataset.url.replace(":bookmarkable_id", this.bookmarkButtonTarget.dataset.bookmarkableId),
      type: "PATCH",
      data: data,
      dataType: "json",
      success: (response, data) => {this.handleSuccessfulBookmarkToggle(response, data)},
      error: (error) => { this.handleUnsuccesfulBookmark(error)}
    })
  }

  handleSuccessfulBookmarkToggle(response){
    let notyf = new Notyf({
      duration: 750,
      position: {x: "right", y: "top"},
      icon: {className: ["fal", "fa-check"]},
      tagName: "i",
      background: "blue",
    })

    notyf.success(response.data.message);
    if(response.data.action_type == "add_bookmark"){
      this.handleBookmarkAddition();
    } else if(response.data.action_type == "remove_bookmark"){
      this.handleBookmarkRemoval();
    }
  }

  handleUnsuccesfulBookmark(error){
  }

  handleBookmarkAddition(){
    let bookmarkButtonIcon = this.bookmarkButtonTarget.querySelector("[data-behavior='bookmark-icon']");

    if(bookmarkButtonIcon.classList.contains("fas")){
      bookmarkButtonIcon.classList.remove("fas");
      bookmarkButtonIcon.classList.add("fal");
      bookmarkButtonIcon.classList.remove("is-red");
    } else{
      bookmarkButtonIcon.classList.add("fas");
      bookmarkButtonIcon.classList.add("is-red");
    }
  }

  handleBookmarkRemoval(){
    let bookmarkButtonIcon = this.bookmarkButtonTarget.querySelector("[data-behavior='bookmark-icon']");

    if(bookmarkButtonIcon.classList.contains("is-red")){
      bookmarkButtonIcon.classList.remove("is-red");
      bookmarkButtonIcon.classList.remove("fas");
      bookmarkButtonIcon.classList.add("fal");
    }
  }

  displayAuthNotification(e) {
    e.preventDefault();
    let notyf = new Notyf({
                          duration: 2500,
                          position: {x: "right", y: "top"},
                          background: "#FF8380",
                          icon: {className: ["fal", "fa-exclamation-circle"], tagName: "i"}
                        });
    notyf.error("Necesitas iniciar sesión para añadir un producto a favoritos");
  }
}