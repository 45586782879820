import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["label", "radio"];

  toggleRadio(event) {
    let selectedLabel = event.target.closest(
      "[data-frontend--custom-radio-target='label']"
    );

    if (event.target.getAttribute("type") == "radio") {
      var selectedRadio = event.target;
      selectedRadio.checked = true;
    } else {
      var selectedRadio = selectedLabel.querySelector("input[type='radio']");
      selectedRadio.checked = true;
    }

    let unselectedLabels = this.labelTargets.filter(
      (label) => label.dataset.name != selectedLabel.dataset.name
    );
    unselectedLabels.map((label) => {
      if (label.classList.contains("checked")) {
        label.classList.remove("checked");
      }
    });

    selectedLabel.classList.add("checked");
  }
}
