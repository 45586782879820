import { Controller } from "stimulus";

const MAX_SELECT_BOX_OPTION_COUNT = 12;

export default class extends Controller {
  static targets = [
    "label",
    "price",
    "newPrice",
    "stockMessage",
    "addToCartActions",
    "percentOff",
  ];

  connect() {
    window.con = this;
  }

  onLabelClick(e) {
    this.makeVariantLabelSelect(e);
    this.markVariantRadioButtonAsChecked(e);
    this.repopulateSelectBoxWithVariantStock(e);
    this.handleStockRelatedEvents(e);
    this.showVariantImageOnCarousel(e);

    if (e.target.dataset.newPrice) {
      this.newPriceTarget.classList.remove("hidden");
      this.priceTarget.innerHTML = e.target.dataset.newPrice;
      this.newPriceTarget.innerHTML = e.target.dataset.price;
      this.percentOffTarget.innerHTML = Math.round(((1 - (parseInt(e.target.dataset.newPrice.split(".")[1]) / parseInt(e.target.dataset.price.split(".")[1]))) * 100))
    } else if (this.newPriceTarget.innerHTML.length > 0) {
      this.newPriceTarget.classList.add("hidden");
      this.priceTarget.innerHTML = e.target.dataset.price;
    } else {
      this.priceTarget.innerHTML = e.target.dataset.price;
    }
  }

  makeVariantLabelSelect(e) {
    e.target.classList.add("selected");
    let unselectedLabels = this.labelTargets.filter(
      (label) => label.dataset.id != e.target.dataset.id
    );
    unselectedLabels.map((label) => {
      if (label.classList.contains("selected")) {
        label.classList.remove("selected");
      }
    });
  }

  markVariantRadioButtonAsChecked(e) {
    e.target.querySelector("input[type='radio']").checked = true;
  }

  showVariantImageOnCarousel(e) {
    const carouselItems = Array.from(
      document.querySelectorAll('[data-action="variant-select.carouselItems"]')
    );
    if (carouselItems.length > 0) {
      const variantId = e.target.dataset.id;
      const imageElement = carouselItems.find(
        (item) => item.dataset.id === variantId
      );

      if (imageElement) {
        imageElement.click();
      }
    }
  }

  repopulateSelectBoxWithVariantStock(e) {
    let selectBox = document.querySelector(
      "[data-behavior='quantity-select-box']"
    );
    let currentStock = e.target.dataset.currentStock;
    let selectOptions = this.constrainedArrayRangeFromNumber(
      parseInt(currentStock)
    );
    this.removeEveryOptionFromSelectBox(selectBox);
    this.populateCheckBoxWithSelectOptions(selectBox, selectOptions);
  }

  handleStockRelatedEvents(e) {
    if (e.target.dataset.currentStock > 0) {
      this.enableAddToCartAndQuantitySelectBox();
      this.setStockMessageToInStock();
    } else {
      this.disableAddToCartAndQuantitySelectBox();
      this.setStockMessageToSoldOut();
    }
  }

  // We generate an array with each of the amount values using the data-current-count
  // attribute from every variant radio button but we limit the max amount to MAX_SELECT_FIELD_VALUE_AMOUNT
  // in case there are products with huge stock (larger than 30 for example) which would make the
  // form messy.
  constrainedArrayRangeFromNumber(n) {
    return [...Array(n).keys()]
      .map((key) => {
        if (key < MAX_SELECT_BOX_OPTION_COUNT) {
          return key + 1;
        }
      })
      .filter((key) => key != undefined);
  }

  removeEveryOptionFromSelectBox(selectBox) {
    for (var i = selectBox.options.length - 1; i >= 0; i--) {
      selectBox.remove(i);
    }
  }

  populateCheckBoxWithSelectOptions(selectBox, options) {
    options.forEach((option) => {
      var optn = document.createElement("option");
      optn.text = option;
      optn.value = option;
      selectBox.options.add(optn);
    });
  }

  enableAddToCartAndQuantitySelectBox() {
    this.addToCartActionsTarget.querySelector(
      "[data-behavior='quantity-select-box']"
    ).disabled = false;
    this.addToCartActionsTarget.querySelector(
      "[data-behavior=cta-button]"
    ).disabled = false;
  }

  disableAddToCartAndQuantitySelectBox() {
    this.addToCartActionsTarget.querySelector(
      "[data-behavior='quantity-select-box']"
    ).disabled = true;
    this.addToCartActionsTarget.querySelector(
      "[data-behavior=cta-button]"
    ).disabled = true;
  }

  setStockMessageToInStock() {
    let messageSpan = this.stockMessageTarget.querySelector("span");
    let messageIcon = this.stockMessageTarget.querySelector(
      "[data-behavior='icon']"
    );

    messageSpan.innerHTML = "En stock";

    if (messageIcon.classList.contains("fa-times")) {
      messageIcon.classList.remove("fa-times");
      messageSpan.classList.remove("is-red");

      messageSpan.classList.add("is-green");
      messageIcon.classList.add("fa-check");
      messageIcon.classList.add("is-green");
    }
  }

  setStockMessageToSoldOut() {
    let messageSpan = this.stockMessageTarget.querySelector("span");
    let messageIcon = this.stockMessageTarget.querySelector(
      "[data-behavior='icon']"
    );

    messageSpan.innerHTML = "Fuera de stock";

    if (messageIcon.classList.contains("fa-check")) {
      messageIcon.classList.remove("fa-check");
      messageIcon.classList.remove("is-green");
      messageSpan.classList.remove("is-green");

      messageSpan.classList.add("is-red");
      messageIcon.classList.add("fa-times");
      messageIcon.classList.add("is-red");
    }
  }
}
