import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab"];

  toggleTab(e) {
    let untoggled = this.tabTargets.filter(
      (tabTarget) => tabTarget.dataset.id != e.target.dataset.toggle
    );
    let toggled = this.tabTargets.find(
      (tabTarget) => tabTarget.dataset.id == e.target.dataset.toggle
    );
    let navTabs = document.querySelectorAll("[data-behavior='tab-navigation']");
    let unselectedTabs = [...navTabs].filter((tab) => tab != e.target);

    unselectedTabs.forEach((tab) => {
      if (tab.classList.contains(this.data.get("css-class"))) {
        tab.classList.remove(this.data.get("css-class"));
      }
    });

    e.target.classList.add(this.data.get("css-class"));

    toggled.classList.add(this.data.get("css-class"));

    untoggled.map((element) => {
      if (element.classList.contains(this.data.get("css-class"))) {
        element.classList.remove(this.data.get("css-class"));
      }
    });
  }
}
