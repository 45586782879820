import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "form", "success", "warning"];

  validates(event) {
    const mailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const inputField = this.fieldTarget.value;

    if (mailRegex.test(inputField)) {
      this.formTarget.submit();
    } else {
      event.preventDefault();
      this.fieldTarget.classList.add("border-danger");
      this.warningTarget.classList.remove("hidden");
    }
  }
}
