import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggler"];

  toggle(e) {
    e.preventDefault();
    let formData = new FormData();
    formData.append(
      this.data.get("param"),
      !JSON.parse(this.data.get("boolean").toLowerCase())
    );

    Rails.ajax({
      url: this.data.get("url"),
      type: "PATCH",
      data: formData,
      success: function (response) {},
      error: function (error) {},
    });
  }
}
