import { Controller } from "stimulus";
import { Notyf } from "notyf";

export default class extends Controller {
  static values = { content: String };
  static targets = ["content"];

  copy(event) {
    event.preventDefault();
    let content = this.contentValue.trim();

    if (navigator.clipboard.writeText(content)) {
      let notyf = new Notyf({
        duration: 750,
        position: { x: "right", y: "top" },
        icon: { className: ["fal", "fa-check"] },
        tagName: "i",
        background: "blue",
      });

      notyf.success("Copiado al portapapeles");
    }
  }
}
