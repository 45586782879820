import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "icon"];

  toggle() {
    const content = this.contentTarget;

    if (content.offsetHeight > 0) {
      // Collapse
      content.style.height = "0";
      content.classList.remove("mt-2");
      this.iconTarget.style.transform = "rotate(0deg)";
    } else {
      // Expand
      content.style.height = `${content.scrollHeight}px`;
      content.classList.add("mt-2");
      this.iconTarget.style.transform = "rotate(180deg)";
    }

    // Listener to reset height after expansion for responsiveness
    content.addEventListener(
      "transitionend",
      () => {
        if (content.offsetHeight > 0) {
          content.style.height = "auto";
        }
      },
      { once: true }
    );
  }
}
