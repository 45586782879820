import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["lat", "lng", "line1", "map", "customMarker"];

  connect() {
    Turbolinks.clearCache();
    if (typeof google != "undefined") {
      this.initMap();
    }
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(-17.7833411, -63.1821504),
      zoom: 14,
      gestureHandling: "greedy",
      disableDefaultUI: true,
    });

    this.geocoder = new google.maps.Geocoder();
  }

  getUserLocation() {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        this._onSuccess.bind(this),
        this._onFailure
      );
    }
  }

  async _onSuccess(response) {
    const latLng = new google.maps.LatLng(
      response.coords.latitude,
      response.coords.longitude
    );

    this.map.setCenter(latLng);
    this.map.setZoom(16);
    this.map.addListener("tilesloaded", () =>
      this.customMarkerTarget.classList.remove("hidden")
    );

    const geocodedAddresses = await this.geocoder.geocode({ location: latLng });

    if (geocodedAddresses.results.length > 0) {
      this.line1Target.value = geocodedAddresses.results[0].formatted_address;
      this.mapTarget.style.display = "block";
    }
  }

  async _mapCenterChange() {
    const latitude = this.map.getCenter().lat();
    const longitude = this.map.getCenter().lng();

    this.latTarget.value = latitude;
    this.lngTarget.value = longitude;

    const latLng = new google.maps.LatLng(latitude, longitude);
    let that = this;

    setTimeout(async function () {
      const geocodedAddress = await that.geocoder.geocode({ location: latLng });
      if (geocodedAddress.results.length > 0) {
        that.line1Target.value = geocodedAddress.results[0].formatted_address;
      }
    }, 400);
  }

  _onFailure(error) {}
}
