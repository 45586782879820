import { Controller } from "stimulus";

const CITY_CENTER_COORDS = {
  lat: -17.7833411,
  lng: -63.1821504,
};

export default class extends Controller {
  static targets = [
    "field",
    "map",
    "lat",
    "lng",
    "googlePlaceId",
    "message",
    "customMarker",
  ];

  connect() {
    if (typeof google != "undefined") {
      this.initMap();
    }
  }

  async initMap() {
    if (this.fieldTarget == null) {
      this.getUserLocation();
    }

    const lat = +this.latTarget.value || CITY_CENTER_COORDS.lat;
    const lng = +this.lngTarget.value || CITY_CENTER_COORDS.lng;

    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    this.map = new Map(this.mapTarget, {
      center: new google.maps.LatLng(lat, lng),
      zoom: 14,
      mapId: "DEMO_MAP_ID",
      gestureHandling: "greedy",
      disableDefaultUI: true,
    });

    this.marker = new AdvancedMarkerElement({
      map: this.map,
      position: { lat: lat, lng: lng },
      title: "",
      gmpDraggable: true,
    });

    this.geocoder = new google.maps.Geocoder();

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget);
    this.autocomplete.bindTo("bounds", this.map);
    this.autocomplete.setFields([
      "address_components",
      "geometry",
      "icon",
      "name",
    ]);
    this.autocomplete.addListener(
      "place_changed",
      this.placeChanged.bind(this)
    );

    this.marker.addListener("dragend", this.markerDragged.bind(this));
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this._geoSuccess.bind(this));
    }
  }

  async _geoSuccess(position) {
    const { latitude, longitude } = position.coords;
    const latLng = new google.maps.LatLng(latitude, longitude);
    this.map.setCenter(latLng);
    this.map.setZoom(16);
    this.messageTarget.style.display = "block";

    this.geocoder.geocode({ location: latLng }, (results) => {
      if (results) {
        this.googlePlaceIdTarget.value = results[0].place_id;
        this.fieldTarget.value = results[0].formatted_address;
        this.mapTarget.style.display = "block";
      }
    });
  }

  async _mapCenterChange() {
    const latitude = this.map.getCenter().lat();
    const longitude = this.map.getCenter().lng();

    this.latTarget.value = latitude;
    this.lngTarget.value = longitude;

    const latLng = new google.maps.LatLng(latitude, longitude);
    let that = this;

    setTimeout(async function () {
      const geocodedAddress = await that.geocoder.geocode({ location: latLng });
      if (geocodedAddress.results.length > 0) {
        that.fieldTarget.value = geocodedAddress.results[0].formatted_address;
        that.googlePlaceIdTarget.value = geocodedAddress.results[0].place_id;
      }
    }, 1000);
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();

    if (place.geometry) {
      this.latTarget.value = place.geometry.location.lat();
      this.lngTarget.value = place.geometry.location.lng();

      this.mapTarget.style.display = "block";

      if (this.hasMessageTarget) {
        this.messageTarget.style.display = "none";
      }

      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
        this.marker.position = place.geometry.location;
      } else {
        this.map.setCenter(place.geometry.location);
        this.marker.position = place.geometry.location;
        this.map.setZoom(16);
      }
    } else {
      this.map.setZoom(15);
      this.mapTarget.style.display = "block";
      this.messageTarget.style.display = "block";
    }
  }

  markerDragged(e) {
    this.latTarget.value = e.latLng.lat();
    this.lngTarget.value = e.latLng.lng();

    let latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    this.map.setCenter(latLng);
    this.map.setZoom(18);

    this.geocoder.geocode({ location: latLng }, (results) => {
      if (results) {
        this.fieldTarget.value = results[0].formatted_address;
        this.googlePlaceIdTarget.value = results[0].place_id;
      }
    });
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault();
    } else if (event.key == "Tab") {
      this.mapTarget.style.display = "block";
      this.messageTarget.style.display = "block";
    }
  }

  mouseleave(event) {
    this.mapTarget.style.display = "block";
    this.messageTarget.style.display = "block";
  }
}
