// Exe JQuery bullshit
$(".carousel").carousel({
  interval: 2000,
  touch: true,
});

window.addEventListener("turbolinks:load", () => {
  const carouselSlider = document.querySelectorAll(".carousel-indicator");

  const btnUp = document.querySelector(".carousel-up-btn");
  const btnDown = document.querySelector(".carousel-down-btn");

  const indicatorHeight = 74;
  let currentHeight = indicatorHeight;

  // BUTTON DOWN
  function btnDownCallback() {
    Array.from(carouselSlider).forEach((indicator) => {
      indicator.style.transition = "transform 0.4s ease-in-out";
      indicator.style.transform = "translateY(" + -currentHeight + "px)";
    });
    currentHeight += indicatorHeight;
  }

  const options = {
    root: document.querySelector(".indicators-container"),
    threshold: 0.9,
  };

  const observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting && btnDown !== null) {
        btnDown.addEventListener("click", btnDownCallback);
      } else {
        if (btnDown === null) {
          return  
        }
        else{
        btnDown.removeEventListener("click", btnDownCallback);
        }
      }
    });
  }, options);

  if (carouselSlider.length > 0) {
    observer.observe(carouselSlider[carouselSlider.length - 1]);
  }

  function btnUpCallback() {
    currentHeight -= indicatorHeight;
    Array.from(carouselSlider).forEach((indicator) => {
      indicator.style.transition = "transform 0.4s ease-in-out";
      indicator.style.transform = "translateY(" + -currentHeight + "px)";
    });
  }

  const upObserver = new IntersectionObserver(function (entries, observer) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting && btnUp !== null) {
        btnUp.addEventListener("click", btnUpCallback);
      } else {
        if(btnUp === null){
          return
        }
        else{
        btnUp.removeEventListener("click", btnUpCallback);
        }
      }
    });
  }, options);

  if (carouselSlider.length > 0) {
    upObserver.observe(carouselSlider[0]);
  }
});
