import { Controller } from "stimulus";
import { useDebounce } from "stimulus-use";

const escapeKey = 27;

export default class extends Controller {
  static targets = ["query", "results", "clearSearchIcon", "spinner"];

  static debounces = [
    "keyup",
    {
      name: "fetchResults",
      wait: 180,
    },
  ];

  connect() {
    useDebounce(this);
  }

  disconnect() {
    this._reset();
  }

  fetchResults() {
    if (this.query == "") {
      this._reset();
      return;
    }

    if (this.query == this.previousQuery) {
      return;
    }

    this.previousQuery = this.query;

    const url = new URL(this.data.get("url"));
    url.searchParams.append("q", this.query);

    this._abortPreviousFetchRequest();

    this.abortController = new AbortController();
    fetch(url, { signal: this.abortController.signal })
      .then((response) => response.text())
      .then((html) => {
        this.resultsTarget.innerHTML = html;
      })
      .catch(() => {});
  }

  navigateResults(event) {
    if (this.searchResultsController) {
      this.searchResultsController.navigateResults(event);
    }
  }

  handleKeyUp(e) {
    // Close the search results if the user presses the escape key
    if (e.keyCode == escapeKey) {
      this.disconnect();
    }

    // Show the clear search icon if the user types something
    if (this.queryTarget.value) {
      this.clearSearchIconTarget.classList.remove("hidden");
    } else {
      this.clearSearchIconTarget.classList.add("hidden");
    }
  }

  handleOutsideClick(e) {
    if (e.target != this.queryTarget) {
      this.disconnect();
    }
  }

  clearSearch() {
    this._reset();
  }

  _reset() {
    this.resultsTarget.innerHTML = "";
    this.queryTarget.value = "";
    this.previousQuery = null;
  }

  _abortPreviousFetchRequest() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  get query() {
    return this.queryTarget.value;
  }

  get searchResultsController() {
    return this.application.getControllerForElementAndIdentifier(
      this.resultsTarget.firstElementChild,
      "search-results"
    );
  }
}
