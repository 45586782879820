window.addEventListener("turbolinks:load", () => {
  let slickable = document.querySelectorAll("[data-behavior=slickable-banner]");

  slickable.forEach((slick) => {
    $(slick).on("init", function (event, slick) {
      $(this).fadeIn(900);
    });

    $(slick).slick({
      autoplay: true,
      infinite: true,
      slidesToShow: 1,
      lazyLoad: "ondemand",
      autoplaySpeed: 5000,
      speed: 900,
      arrows: false,
    });
  });
});
