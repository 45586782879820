import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["line_1", "phone_number", "landmark", "address_id"];

  handleAddressChange(e) {
    let addressURL = e.target.dataset.url.replace(/:ID/g, e.target.value);
    console.log(e.target.value);

    Rails.ajax({
      url: addressURL,
      type: "GET",
      success: (response) => {
        this._updateAddress(response);
      },
    });
  }

  handleTaxIdChange(e) {
    e.preventDefault();
    let taxIdURL = e.target.dataset.url.replace(/:ID/g, e.target.value);
  }

  _updateAddress(address) {
    this.address_idTarget.value = address.id;
    this.line_1Target.innerHTML = `<p>${address.line_1}</p>`;
    this.phone_numberTarget.innerHTML = `<p>${address.phone_number}</p>`;
    if (this.landmarkTarget) {
      this.landmarkTarget.innerHTML = `<p>${address.landmark}</p>`;
    }
  }
}
