document.addEventListener("turbolinks:load", function () {
  const toggleButtons = document.querySelectorAll(".read-more-toggle");

  toggleButtons.forEach((button) => {
    button.addEventListener("click", function () {
      const container = this.closest(".read-more-container");
      const textContainer = container.querySelector(".read-more-text");
      const overlay = container.querySelector(".read-more-overlay");
      const button = container.querySelector(".read-more-toggle");
      const isCollapsed = JSON.parse(textContainer.dataset.collapsed);
      const maxHeight = textContainer.dataset.maxHeight;

      if (isCollapsed) {
        const sectionHeight = textContainer.scrollHeight;
        textContainer.style.height = sectionHeight + "px";
        overlay.style.display = "none";
        button.innerText = "Leer menos";
      } else {
        textContainer.style.height = maxHeight + "px";
        overlay.style.display = "block";
        button.innerText = "Leer más";
      }

      textContainer.dataset.collapsed = !isCollapsed;
    });
  });
});
