import { Controller } from "stimulus";

export default class extends Controller {
  dismiss() {
    this.element.classList.add("fade-out");

    this.element.onanimationend = (e) => {
      if (e.srcElement.classList.contains("fade-out")) {
        this.element.remove();
      }
    };

    if (this.data.get("url")) {
      const url = this.data.get("url");

      Rails.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        success: (response, data) => {},
        error: (error) => {
          // Do something
        },
      });
    }
  }
}
