import { Controller } from "stimulus";
import Turbolinks from "turbolinks";
import { Notyf } from "notyf";

/* To make this controller as agnostic as possible we want to make 
sure that we can use it in any checkout-related circumstance
In order to do this we should:
1. Not use any specific element in the DOM
2. Just make the basic assumptions needed: this means that the controller
should deal with the least amount of external information as possible.
For example, the controller should not know much about the specific discount applied
to the order except the subtotal, total, discounted_amount and specific discounts for 
each item. This means that we should just query the backend and have a generic response
back from the server that we use to update the UI.
3. The targets should be: item list, subtotal, total, shipping
4. The data returned by the server should always be in the following format: 
{total: 0, subtotal: 0, shipping: 0, discount: 0, items: [{id: 0, discount: 0}, {id: 1, discount: 0}]}"}
5. If we can successfully apply a discount, the discount should persist for as long as the user
keeps the cart without deleting.

*/
export default class extends Controller {
  static targets = [
    "item",
    "subtotal",
    "total",
    "shipping",
    "discountContainer",
    "discountAmount",
    "field",
    "loadingAnimation",
  ];
  static values = { url: String, cartId: Number };

  disconnect() {
    Turbolinks.clearCache();
  }

  apply(e) {
    e.preventDefault();
  }

  onFieldBlur(e) {
    e.preventDefault();
    this._showLoadingAnimation();

    const data = new FormData();
    const self = this;
    data.append("coupon[code]", this.fieldTarget.value);
    data.append("coupon[cart_id]", this.cartIdValue);

    Rails.ajax({
      url: this.urlValue,
      type: "POST",
      dataType: "json",
      data: data,
      success: (response) => {
        self._updateUI(response);
      },
      error: (errors) => {
        self._showErrorFeedback(errors);
        self._hideLoadingAnimation();
      },
    });
  }

  onFieldChange(event) {
    this.fieldTarget.value = event.target.value.toUpperCase();
  }

  _updateUI(response) {
    // Give discount the adjustment value and toggle the .hidden class
    this._hideLoadingAnimation();
    this.discountContainerTarget.classList.remove("hidden");
    this.discountContainerTarget.querySelector(
      '[data-behavior="discount-amount"]'
    ).innerHTML = response.adjustment;
    this.shippingTarget.innerHTML = response.shipping;

    // Update the order total
    this.totalTarget.innerHTML = response.total;
    const notyf = new Notyf({
      duration: 3000,
      position: {
        x: "right",
        y: "top",
      },
      types: [
        {
          type: "success",
          background: "#29231e",
          icon: {
            className: ["fal", "fa-check", "is-white"].join(" "),
            tagName: "i",
          },
        },
      ],
    });
    notyf.success("Descuento aplicado con éxito");
  }

  _showLoadingAnimation() {
    this.loadingAnimationTarget.classList.remove("hidden");
  }

  _hideLoadingAnimation() {
    this.loadingAnimationTarget.classList.add("hidden");
  }

  _showErrorFeedback(errors) {
    let notyf = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
      background: "#FF8380",
      icon: { className: ["fal", "fa-exclamation-circle"], tagName: "i" },
    });

    notyf.error(errors.join("<br/>"));
  }
}
