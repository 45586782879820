import { Controller } from "stimulus";

export default class extends Controller {
  static values = { disableDefaultUi: String };
  static targets = ["map"];

  connect() {
    Turbolinks.clearCache();
    if (typeof google != "undefined") {
      this.initMap();
    }
  }

  initMap() {
    let coordinates = {
      lat: parseFloat(this.element.dataset.lat),
      lng: parseFloat(this.element.dataset.lng),
    };

    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(coordinates["lat"], coordinates["lng"]),
      zoom: 14,
      disableDefaultUI: JSON.parse(this.disableDefaultUiValue),
      zoomControl: true,
    });

    if (this.element.dataset.circle) {
      if (JSON.parse(this.element.dataset.circle)) {
        this.circle = new google.maps.Circle({
          center: { lat: coordinates["lat"], lng: coordinates["lng"] },
          radius: parseInt(this.element.dataset.circleRadius),
          map: this.map,
          fillColor: "blue",
          strokeColor: "transparent",
        });
      }
    }

    this.marker = new google.maps.Marker({
      position: { lat: coordinates["lat"], lng: coordinates["lng"] },
      map: this.map,
      draggable: false,
    });
  }
}
