import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["scrollable"];

  connect() {
    this.step = 300;
  }

  scrollRight(event) {
    this.scrollableTarget.scrollBy({ left: this.step, behavior: "smooth" });
  }

  scrollLeft(event) {
    this.scrollableTarget.scrollBy({
      left: -this.step,
      behavior: "smooth",
    });
  }
}
