// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("channels");

require("slick-carousel");
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

import "bootstrap";
import "./carousel.js";
import "./read_more_component.js";
import "./slick.js";
import "./slick_banner.js";
import "./soft_scroll.js";
import "./tooltip.js";
import "../stylesheets/application";

// Stimulus controllers
import "../controllers/frontend";

window.initMap = (...args) => {
  const event = document.createEvent("Events");
  event.initEvent("google-maps-callback", true, true);
  event.args = args;
  window.dispatchEvent(event);
};
